<template>
  <div class="product">
    <van-nav-bar
      title="检测列表"
      left-text="返回"
      right-text="筛选"
      left-arrow
      @click-left="toHome"
      @click-right="chooseshow = true"
    />
    <div class="btn-grp">
      <van-button
        plain
        round
        type="default"
        size="small"
        @click="statusGet('0', '待检测')"
        >待检测</van-button
      >
      <van-button
        plain
        round
        type="default"
        size="small"
        @click="statusGet('1', '检测中')"
        >检测中</van-button
      >
      <van-button
        plain
        round
        type="default"
        size="small"
        @click="statusGet('2', '初检完成')"
        >初检完成</van-button
      >
      <van-button
        plain
        round
        type="default"
        size="small"
        @click="statusGet('6', '复检审核')"
        >复检审核</van-button
      >
    </div>
    <!-- 下拉刷新 -->
    <van-pull-refresh
      v-model="refreshing"
      success-text="刷新成功"
      @refresh="onRefresh"
    >
      <van-list
        v-model="loading"
        :finished="finished"
        :immediate-check="true"
        finished-text="没有更多了"
        @load="onLoad"
      >
        <van-cell-group
          class="mt10"
          v-for="(item, idx) in sampleList"
          :key="idx"
        >
          <van-cell>
            <template #title>
              <!-- <van-icon class="status" v-if="(item.check_status == 3 || item.check_status == 5) && item.conclusion == 1" class-prefix="icon-font" name="hege" color="#07c160" size="42"/>
              <van-icon class="status" v-if="(item.check_status == 3 || item.check_status == 5) && item.conclusion == 0" class-prefix="icon-font" name="buhege" color="#ee0a24" size="42"/>          -->
              <van-row type="flex" justify="space-between">
                <van-col span="12">{{ item.inspect.inspect_code }}</van-col>
                <van-col span="12" class="tr" v-if="item.check_status == 0"
                  ><van-tag type="primary">待检测</van-tag></van-col
                >
                <van-col span="12" class="tr" v-if="item.check_status == 1"
                  ><van-tag type="danger">检测中</van-tag></van-col
                >
                <van-col span="12" class="tr" v-if="item.check_status == 2"
                  ><van-tag type="warning">初检完成</van-tag></van-col
                >
                <van-col span="12" class="tr" v-if="item.check_status == 3"
                  ><van-tag type="success">检测完成</van-tag></van-col
                >
                <van-col span="12" class="tr" v-if="item.check_status == 4"
                  ><van-tag type="danger">让步审批中</van-tag></van-col
                >
                <van-col span="12" class="tr" v-if="item.check_status == 5"
                  ><van-tag type="success">审批完成</van-tag></van-col
                >
                <van-col span="12" class="tr" v-if="item.check_status == 6"
                  ><van-tag type="success">复检审核</van-tag></van-col
                >
              </van-row>
              <van-row type="flex" justify="space-between">
                <van-col span="24"
                  ><h4 class="custom-title">
                    报检样品：{{ item.inspect.sample_name }}
                  </h4></van-col
                >
              </van-row>
              <van-row type="flex" justify="space-between">
                <van-col span="24"
                  >批次：{{ item.inspect.inspect_batch }}</van-col
                >
              </van-row>
              <van-row type="flex" justify="space-between">
                <van-col span="12"
                  >类型：{{ item.inspect.type.type_name }}</van-col
                >
                <van-col span="12" class="tr"
                  ><span
                    v-if="
                      item.check_status == 2 ||
                      item.check_status == 3 ||
                      item.check_status == 4 ||
                      item.check_status == 5
                    "
                  >
                    <!-- 结果：{{item.conclusion == 1 ? '合格': '不合格'}} -->
                    <van-icon
                      class="status"
                      v-if="item.conclusion == 1"
                      class-prefix="icon-font"
                      name="hege"
                      color="#07c160"
                      size="42"
                    />
                    <van-icon
                      class="status"
                      v-if="item.conclusion == 0"
                      class-prefix="icon-font"
                      name="buhege"
                      color="#ee0a24"
                      size="42"
                    /> </span
                ></van-col>
                <!-- <van-icon class="status" v-if="dataList.conclusion == 1" class-prefix="icon-font" name="hege" color="#07c160" size="42"/>
                <van-icon class="status" v-if="dataList.conclusion == 0" class-prefix="icon-font" name="buhege" color="#ee0a24" size="42"/> -->
              </van-row>
              <van-row type="flex" justify="space-between">
                <van-col span="24"
                  >报检人：{{ item.inspect.inspect_emp_name }} [{{
                    item.inspect.inspect_dep_name
                  }}]</van-col
                >
                <!-- <van-col span="24">是否复检：{{item.is_inspect_recheck == '1'? '是' : '否'}}</van-col> -->
                <van-col span="24"
                  >报检时间：{{ item.inspect.inspect_time }}</van-col
                >
                <!-- <van-col span="24" >检测时间：{{item.check_time}}</van-col> -->
              </van-row>
              <van-row type="flex" justify="end">
                <van-button
                  class="ml5"
                  plain
                  hairline
                  round
                  type="info"
                  size="small"
                  @click="detection(item)"
                  v-track="{
                    triggerType: 'click',
                    title: '检测详情',
                    currentUrl: $route.path,
                    behavior: item.inspect.inspect_code,
                    duration: new Date().getTime(),
                  }"
                  >详 情</van-button
                >
                <van-button
                  class="ml5"
                  plain
                  hairline
                  round
                  type="info"
                  size="small"
                  v-show="item.check_status == 0"
                  @click="checkBtn(item)"
                  >开始检测</van-button
                >
                <van-button
                  class="ml5"
                  plain
                  hairline
                  round
                  type="info"
                  size="small"
                  v-show="item.check_status == 1 || item.is_publish == '1'"
                  @click="checkBtn(item)"
                  >初 检</van-button
                >
                <van-button
                  class="ml5"
                  plain
                  hairline
                  round
                  type="info"
                  v-if="role == 'SuperRole'"
                  size="small"
                  v-show="item.check_status == 2"
                  @click="checkBtn(item)"
                  >复 检</van-button
                >
                <van-button
                  class="ml5"
                  plain
                  hairline
                  round
                  type="info"
                  v-else
                  size="small"
                  v-show="item.check_status == 2 && uid == item.recheck_emp_id"
                  @click="checkBtn(item)"
                  >复 检</van-button
                >
                <van-button
                  class="ml5"
                  plain
                  hairline
                  round
                  type="info"
                  size="small"
                  v-show="item.check_status == 2"
                  @click="changeBtn(item)"
                  >更换复检人</van-button
                >
                <van-button
                  class="ml5"
                  plain
                  hairline
                  round
                  type="info"
                  size="small"
                  v-show="
                    item.check_status == 3 && is_modify_result_auth == '1'
                  "
                  @click="checkBtn(item)"
                  >复检修改</van-button
                >
                <van-button
                  class="ml5"
                  plain
                  hairline
                  round
                  type="info"
                  v-if="role == 'SuperRole'"
                  size="small"
                  v-show="item.check_status == 4"
                  @click="rbBtn(item)"
                  >让步审批</van-button
                >
                <van-button
                  class="ml5"
                  plain
                  hairline
                  round
                  type="info"
                  v-else
                  size="small"
                  v-show="item.check_status == 4 && uid == item.approval_emp_id"
                  @click="rbBtn(item)"
                  >让步审批</van-button
                >
                <van-button
                  class="ml5"
                  plain
                  hairline
                  round
                  type="info"
                  v-show="item.check_status == 1"
                  size="small"
                  @click="resetBtn(item)"
                  >退 回</van-button
                >
                <van-button
                  class="ml5"
                  plain
                  hairline
                  round
                  type="info"
                  size="small"
                  v-show="item.check_status == 6"
                  @click="recheckBtn(item)"
                  >复检审核</van-button
                >
                <!-- <van-button class="ml5" plain hairline round type="info" size="small" @click="resetBtn(item)" >重 置</van-button> -->
                <van-button
                  class="ml5"
                  plain
                  hairline
                  round
                  type="info"
                  size="small"
                  @click="showQRcode(idx)"
                  >打印标签</van-button
                >
              </van-row>
            </template>
          </van-cell>
        </van-cell-group>
      </van-list>
    </van-pull-refresh>

    <van-popup v-model="showfjPicker" round position="bottom">
      <van-picker
        show-toolbar
        title="选择复检人"
        :columns="fjList"
        value-key="username"
        @cancel="showfjPicker = false"
        @confirm="fjConfirm"
        v-track="{
          triggerType: 'click',
          title: '更换复检人',
          currentUrl: $route.path,
          behavior: this._data,
          duration: new Date().getTime(),
        }"
      />
    </van-popup>
    <van-popup round v-model="chooseshow" position="top">
      <van-field
        readonly
        clickable
        name="start_time"
        :value="params.start_time"
        label="开始时间"
        placeholder="点击选择时间"
        @click-input="stimeShow = true"
      />
      <van-field
        v-model="params.end_time"
        name="end_time"
        label="结束时间"
        @click-input="etimeShow = true"
        readonly
      />
      <van-field
        readonly
        v-model="params.sample_name"
        placeholder="输入样品关键词"
        name="sample_name"
        label="样品名称"
        @click="showSample"
      />
      <van-field
        readonly
        clickable
        name="inspect_type"
        :value="params.type_name"
        label="报检类型"
        placeholder="点击选择报检类型"
        @click-input="showType"
      />
      <van-field
        readonly
        clickable
        name="inspect_dep_id"
        :value="params.inspect_dep_name"
        label="报检部门"
        placeholder="点击选择报检部门"
        @click-input="showDep"
      />
      <van-field
        style="display: none"
        readonly
        clickable
        name="equipment_id"
        :value="params.equipment_name"
        label="检测设备"
        placeholder="点击选择检测设备"
        @click-input="showEqu"
      />
      <van-field
        readonly
        clickable
        name="conclusion"
        :value="params.conclusion_val"
        label="检测结果"
        placeholder="点击选择检测结果"
        @click-input="showHg"
      />
      <van-field
        readonly
        clickable
        name="check_status"
        :value="params.check_status_val"
        label="审核状态"
        placeholder="点击选择审核状态"
        @click-input="showCheck"
      />
      <van-row style="margin: 16px" gutter="20">
        <van-col span="8"
          ><van-button
            round
            block
            plain
            size="small"
            type="default"
            native-type="button"
            @click="clickRest()"
            >重置</van-button
          ></van-col
        >
        <van-col span="16"
          ><van-button
            title="筛选"
            round
            block
            size="small"
            type="primary"
            @click="clickBtn()"
            >确定</van-button
          ></van-col
        >
        <!-- <van-col span='10'>
          <van-button round block type="info" @click="clickBtn()">确定</van-button>
        </van-col>
        <van-col span='4'></van-col>
        <van-col span='10'>
          <van-button round block type="info" @click="clickRest()">重置</van-button>
        </van-col> -->
      </van-row>
    </van-popup>
    <!--开始年月日  -->
    <van-popup v-model="stimeShow" position="bottom">
      <van-datetime-picker
        type="date"
        :min-date="minDate"
        :max-date="maxDate"
        @confirm="stimeConfirm"
        @cancel="stimeShow = false"
      />
    </van-popup>
    <!-- 结束时间 -->
    <van-popup v-model="etimeShow" position="bottom">
      <van-datetime-picker
        type="date"
        :min-date="minDate"
        :max-date="maxDate"
        @confirm="etimeConfirm"
        @cancel="etimeShow = false"
      />
    </van-popup>
    <!-- 产品搜索 -->
    <van-popup
      v-model="sampleShow"
      position="bottom"
      round
      :style="{ height: '90%' }"
    >
      <van-search
        v-model="params.sample_name"
        placeholder="请输入报检产品关键词"
        @input="searchSample"
        :autofocus="autofocus"
      />
      <div style="height: 90%; overflow: auto">
        <van-cell
          v-for="(item, idx) in productList"
          :key="idx"
          :title="item.sample_name + ' ' + item.sample_specs"
          @click="toProduct(item.sample_name, item.sample_code)"
        />
      </div>
    </van-popup>
    <!-- 报检类型 -->
    <van-popup v-model="typeShow" position="bottom">
      <van-picker
        show-toolbar
        value-key="type_name"
        :columns="types"
        @confirm="typeConfirm"
        @cancel="typeShow = false"
      />
    </van-popup>
    <!-- 报检部门 -->
    <van-popup v-model="depShow" position="bottom">
      <van-picker
        show-toolbar
        value-key="dd_dep_name"
        :columns="depTypes"
        @confirm="depConfirm"
        @cancel="typeShow = false"
      />
    </van-popup>
    <!-- 报检设备 -->
    <van-popup v-model="equShow" position="bottom">
      <van-picker
        show-toolbar
        value-key="equipment_name"
        :columns="equTypes"
        @confirm="equConfirm"
        @cancel="equShow = false"
      />
    </van-popup>
    <!-- 检测结果 -->
    <van-popup v-model="hgShow" position="bottom">
      <van-picker
        show-toolbar
        value-key="val"
        :columns="hgList"
        @confirm="hgConfirm"
        @cancel="hgShow = false"
      />
    </van-popup>
    <!-- 审核状态 -->
    <van-popup v-model="checkShow" position="bottom">
      <van-picker
        show-toolbar
        value-key="cheName"
        :columns="checkList"
        @confirm="checkConfirm"
        @cancel="checkShow = false"
      />
    </van-popup>

    <!-- 打印标签 -->
    <van-popup v-model="QRcodeShow" position="bottom">
      <div class="label" id="printMe">
        <van-row class="qrlist" type="flex">
          <van-col span="24" class="qr-tt"
            ><p class="">{{ currentItem.sample_name }}</p></van-col
          >
          <van-col span="12" class="qr-lt">
            <p class="fs16">批号：{{ currentItem.inspect_batch }}</p>
            <p v-if="currentItem.inspect_num">
              数量：{{ currentItem.inspect_num }}
            </p>
            <p>报检：{{ currentItem.inspect_emp_name }}</p>
            <p>日期：{{ currentItem.inspect_time }}</p>
            <p v-if="currentItem.car_number">
              车牌号：{{ currentItem.car_number }}
            </p>
            <p v-if="currentItem.contract_no">
              其他：{{ currentItem.contract_no }}
            </p>
          </van-col>
          <van-col span="12" class="qr-rt">
            <QRcode
              :width="code.with"
              :height="code.height"
              :content="code.content"
              :load="false"
            ></QRcode>
            <p class="tc">ID：{{ currentItem.id }}</p>
          </van-col>
          <van-col v-if="currentItem.supplier_name" span="24" class="qr-bt"
            ><p>供应商：{{ currentItem.supplier_name }}</p></van-col
          >
        </van-row>
      </div>
      <van-button
        class="printBtn"
        round
        plain
        type="default"
        v-print="print"
        v-track="{
          triggerType: 'click',
          title: '打印检测标签',
          currentUrl: $route.path,
          behavior: currentItem.inspect_code,
          duration: new Date().getTime(),
        }"
        >立即打印</van-button
      >
    </van-popup>
  </div>
</template>
<script>
import Vue from "vue"
import {
  List,
  Cell,
  CellGroup,
  Tag,
  Col,
  Row,
  Button,
  Popup,
  Field,
  Toast,
  Dialog,
  Icon,
  DropdownMenu,
  DropdownItem,
  ActionSheet,
  Image as VanImage,
  NavBar,
  DatetimePicker,
  Picker,
  Search,
  Loading,
  PullRefresh,
} from "vant";
import { mapActions } from "vuex"

Vue.use(List);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(Tag);
Vue.use(Col);
Vue.use(Row);
Vue.use(Button);
Vue.use(Popup);
Vue.use(Field);
Vue.use(Toast);
Vue.use(Dialog);
Vue.use(Icon);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(ActionSheet);
Vue.use(VanImage);
Vue.use(NavBar);
Vue.use(DatetimePicker);
Vue.use(Picker);
Vue.use(Search);
Vue.use(Loading);
Vue.use(PullRefresh);
const Stime = new Date(new Date().setDate(new Date().getDate() - 14))
export default {
  name: "AnalysisList",
  components: {},
  data() {
    return {
      role: this.$store.state.router.user.role,
      uid: this.$store.state.router.user.uid,
      count: 0,
      searchValue: "",
      loading: false,
      refreshing: false,
      finished: false,
      chooseshow: false,
      stimeShow: false,
      etimeShow: false,
      sampleShow: false,
      typeShow: false,
      depShow: false,
      equShow: false,
      hgShow: false,
      checkShow: false,
      productList: [],
      sampleList: [], //样品列表
      types: [], //报检类型
      depTypes: [], //报检部门
      equTypes: [], //设备列表
      currentItem: {},
      QRcodeShow: false,
      idx: "",
      autofocus: false,
      code: {
        with: "120",
        height: "120",
        content: "",
      },
      print: {
        id: "printMe",
        popTitle: "打印标签", // 打印配置页上方的标题
        extraHead: "", // 最上方的头部文字，附加在head标签上的额外标签，使用逗号分割
        preview: false, // 是否启动预览模式，默认是false
        previewTitle: "打印预览", // 打印预览的标题
        previewPrintBtnLabel: "预览结束，开始打印", // 打印预览的标题下方的按钮文本，点击可进入打印
        zIndex: 999, // 预览窗口的z-index，默认是20002，最好比默认值更高
        standard: "",
        extarCss: "",
      },
      hgList: [
        { val: "全部", val_id: "" },
        { val: "合格", val_id: "1" },
        { val: "不合格", val_id: "0" },
      ],
      checkList: [
        { cheName: "待检测", cheId: "0" },
        { cheName: "检测中", cheId: "1" },
        { cheName: "初检完成", cheId: "2" },
        { cheName: "检测完成", cheId: "3" },
        { cheName: "让步审批中", cheId: "4" },
        { cheName: "审批完成", cheId: "5" },
      ],
      params: {
        start_time: this.$common.formatDate(Stime),
        end_time: this.$common.formatDate(new Date()),
        sample_name: "",
        sample_code: "",
        inspect_type: "",
        inspect_dep_id: "",
        inspect_emp_id: "",
        conclusion: "",
        is_inspect_recheck: "",
        equipment_id: "",
        check_status: "",
        check_status_val: "",
        is_concession_acceptance: "",
        check_emp_id: "",
        recheck_emp_id: "",
        page: 1,
        offset: 10,
      },
      minDate: new Date(2022, 0, 1),
      maxDate: new Date(),
      showfjPicker: false,
      fjList: [],
      currentId: "",
      userName: "",
      is_modify_result_auth: "",
      savedScrollPosition: null,
    };
  },
  created: function () {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      this.userName = user.dd_data.dd_user_name;
      this.uid = user.user_id;
      this.role = user.role;
    } else {
      this.asyncUser().then((res) => {
        this.userName = res.dd_data.dd_user_name;
        this.uid = res.uid;
        this.role = res.role;
      });
    }
    this.getQx();
  },
  // beforeRouteLeave(to, from, next) {
  //   // 在组件离开之前保存滚动位置
  //   // this.savedScrollPosition = { x: window.pageXOffset, y: window.pageYOffset }
  //   // console.log(this.savedScrollPosition)
  //   // next()
  //   this.$store.dispatch('saveScrollPosition')
  //   next()
  // },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      const Params = vm.$store.state.listData?.Params
      if (Object.keys(Params).length > 0) {vm.params = JSON.parse(Params)}
    })
  },
  methods: {
    ...mapActions("listData", ["asyncsetSampleType", 'asyncSetParams']),
    ...mapActions("router", ["asyncUser"]),
    onLoad() {
      const that = this
      const params = this.params
      // this.loading = true;
      if (this.refreshing) {
        this.sampleList = []
        this.params.page = 1
        this.refreshing = false
      }
      this.$get("check/get-check-list", "api", params)
        .then((res) => {
          const data = res.data.list;
          data.forEach((ele) => {
            ele.check_time = that.$common.initDate(ele.check_time);
            ele.inspect.inspect_time = that.$common.initDate(
              ele.inspect.inspect_time
            );
          });
          this.count = res.data.count;
          this.sampleList = this.sampleList.concat(data); // 追加数据
          this.loading = false;
          if (this.sampleList.length < res.data.count) {
            this.params.page++;
          } else {
            this.finished = true;
          }
          // that.$store.dispatch("restoreScrollPosition");
        })
        .catch(() => {
          this.loading = false;
          this.finished = true;
        })
    },
    // 获取权限
    getQx() {
      this.$get("user/get-user-info", "api", "").then(
        (res) => {
          this.is_modify_result_auth = res.data.is_modify_result_auth;
        },
        (err) => {
          this.$notify({ type: "warning", message: err.message });
        }
      );
    },
    // 样品名称
    showSample() {
      this.autofocus = true;
      this.sampleShow = true;
      this.searchSample(this.params.sample_name);
    },
    // 报检类型
    showType() {
      this.typeShow = true;
      this.getTypes();
    },
    // 报检部门
    showDep() {
      this.depShow = true;
      this.getDep();
    },
    // 检测设备
    showEqu() {
      this.equShow = true;
      this.getEqu();
    },
    // 检测结果
    showHg() {
      this.hgShow = true;
    },
    // 审核状态
    showCheck() {
      this.checkShow = true;
    },
    // 获取产品名
    searchSample(val) {
      this.$get("inspect/get-sample-list", "api", {
        page: 1,
        offset: 20,
        sample_name: val,
      }).then(
        (res) => {
          this.productList = res.data.list;
        },
        (err) => {
          this.$notify({ type: "warning", message: err.message });
          this.productList = [];
        }
      );
    },
    // 获取报检类型
    getTypes() {
      const that = this;
      let SampleType = this.$store.state.listData.SampleType;
      if (SampleType.length === 0) {
        that
          .$get("sample-type/get-sample-type-list", "api", {
            page: 1,
            offset: 100,
          })
          .then(
            (res) => {
              that.types = res.data.list;
              that.asyncsetSampleType(that.types);
            },
            (err) => {
              this.$notify({ type: "warning", message: err.message });
            }
          );
      } else {
        that.types = SampleType;
      }
      this.typeStatus = true;
    },
    // 获取报检部门
    getDep() {
      this.$get("inspect/get-inspect-dep-list", "api", {}).then(
        (res) => {
          this.depTypes = res.data.list;
        },
        (err) => {
          this.$notify({ type: "warning", message: err.message });
        }
      );
    },
    // 获取检测设备
    getEqu() {
      this.$get("equipment/get-equipment-list", "api", {
        page: 1,
        offset: 100,
        equipment_name: "",
      }).then(
        (res) => {
          var data = res.data.list;
          data.forEach((ele) => {
            ele.equipment_name = ele.equipment_name + "-" + ele.qc_code;
          });
          this.equTypes = data;
        },
        (err) => {
          this.$notify({ type: "warning", message: err.message });
        }
      );
    },
    // 开始日期确认
    stimeConfirm(time) {
      this.params.start_time = this.$common.formatDate(time);
      this.stimeShow = false;
    },
    // 结束日期确认
    etimeConfirm(time) {
      this.params.end_time = this.$common.formatDate(time);
      this.etimeShow = false;
    },
    // 样品名称确认
    toProduct(val, code) {
      this.params.sample_name = val;
      this.params.sample_code = code;
      this.sampleShow = false;
    },
    // 报检类型确认
    typeConfirm(value) {
      this.params.type_name = value.type_name;
      this.params.inspect_type = value.id;
      this.typeShow = false;
    },
    // 报检部门确认
    depConfirm(depVal) {
      this.params.inspect_dep_name = depVal.dd_dep_name;
      this.params.inspect_dep_id = depVal.dd_dep_id;
      this.depShow = false;
    },
    // 报检设备确认
    equConfirm(epuVal) {
      this.params.equipment_name = epuVal.equipment_name;
      this.params.equipment_id = epuVal.id;
      this.equShow = false;
    },
    // 报检结果确认
    hgConfirm(hgVal) {
      this.params.conclusion_val = hgVal.val;
      this.params.conclusion = hgVal.val_id;
      this.hgShow = false;
    },
    // 审核状态确认
    checkConfirm(checkVal) {
      this.params.check_status = checkVal.cheId;
      this.params.check_status_val = checkVal.cheName;
      this.checkShow = false;
    },
    // 点击确定
    clickBtn() {
      this.sampleList = [];
      this.params.page = 1;
      this.onLoad();
      this.chooseshow = false;
    },
    // 点击重置
    clickRest() {
      this.params = {
        start_time: this.$common.formatDate(Stime),
        end_time: this.$common.formatDate(new Date()),
        sample_name: "",
        inspect_type: "",
        inspect_dep_id: "",
        inspect_emp_id: "",
        conclusion: "",
        is_inspect_recheck: "",
        equipment_id: "",
        check_status: "",
        is_concession_acceptance: "",
        check_emp_id: "",
        recheck_emp_id: "",
        page: 1,
        offset: 10,
      };
      this.onLoad();
    },
    statusGet(id, name) {
      this.sampleList = [];
      this.params.check_status = id;
      this.params.check_status_val = name;
      this.params.page = 1;
      this.onLoad();
    },
    toHome() {
      this.$router.push({
        path: "/home",
      });
    },
    // 检测详情
    detection(item) {
      this.$router.push({
        path: "/analysisdetail",
        query: {
          id: item.id,
        },
      });
      // this.$store.dispatch("saveScrollPosition")
      localStorage.setItem("id", item.id)
      this.asyncSetParams(JSON.stringify(this.params))
      // localStorage.setItem("params", JSON.stringify(this.params))
    },
    // 重置
    resetBtn(item) {
      Dialog.confirm({
        message: "确定要执行此操作",
      })
        .then(() => {
          this.$post("check/revoke-check", "api", { id: item.id }).then(
            (res) => {
              this.$notify({ type: "success", message: res.message });
              this.sampleList = [];
              this.finished = false;
              this.loading = true;
              this.params.page = 1;
              this.onLoad();
            },
            (err) => {
              this.$notify({ type: "warning", message: err.message });
            }
          );
        })
        .catch(() => {
          // on cancel
        });
    },
    // 更换复检人
    changeBtn(item) {
      this.currentId = item.id;
      this.showfjPicker = true;
      this.getfjList();
    },
    // 获取复检人
    getfjList() {
      var that = this;
      var params = {
        page: 1,
        offset: 100,
        username: "",
        is_accept_auth: "",
        is_recheck_auth: 1,
      };
      this.showfjPicker = true;
      var newArr = [];
      this.$get("user/get-user-list", "api", params).then(
        (res) => {
          var data = res.data.data;
          data.forEach((ele) => {
            if (ele.username == that.userName) {
              return;
            } else {
              newArr.push(ele);
            }
          });
          that.fjList = newArr;
        },
        (err) => {
          console.log(err);
        }
      );
    },
    // 复检人确认
    fjConfirm(item) {
      var params = {
        id: this.currentId,
        recheck_emp_id: item.uid,
        recheck_emp_name: item.username,
      };
      this.$post("check/change-recheck-emp", "api", params).then(
        (res) => {
          this.showfjPicker = false;
          this.$notify({ type: "success", message: res.message });
          this.sampleList = [];
          this.params.page = 1;
          this.params.offset = 10;
          this.onLoad();
        },
        (err) => {
          this.$notify({ type: "success", message: err.message });
        }
      );
    },
    // 检测 复检
    checkBtn(item) {
      const that = this
      if (item.check_status == 0) {
        Dialog.confirm({
          title: item.inspect.sample_name,
          message: "确定要开始检测此产品",
        })
          .then(() => {
            that.$router.push({
              path: "/analysistb",
              query: {
                id: item.id,
              },
            })
            localStorage.setItem("checkId", JSON.stringify(item.id))
            // that.$store.dispatch("saveScrollPosition")
            localStorage.setItem("id", item.id)
            that.asyncSetParams(JSON.stringify(this.params))
            // localStorage.setItem("params", JSON.stringify(this.params))
          })
          .catch(() => {
            // on cancel
          })
      } else {
        that.$router.push({
          path: "/analysistb",
          query: {
            id: item.id,
          },
        })
        localStorage.setItem("checkId", JSON.stringify(item.id))
        // that.$store.dispatch("saveScrollPosition")
        localStorage.setItem("id", item.id)
        that.asyncSetParams(JSON.stringify(this.params))
        // localStorage.setItem("params", JSON.stringify(this.params))
      }
      return
    },
    // 让步
    rbBtn(item) {
      this.$router.push({
        path: "/analysisrb",
        query: {
          id: item.id,
        },
      });
      localStorage.setItem("rbId", JSON.stringify(item.id));
    },
    //打印标签
    showQRcode(idx) {
      if (this.$common.isMobilePhone()) {
        Dialog({ message: "请使用PC端打印" });
        return false;
      }
      this.idx = idx;
      this.QRcodeShow = true;
      this.currentItem = this.sampleList[idx].inspect;
      this.code.content =
        "http://lims.cicg.tech/#/sampleDetail?type=1&id=" +
        this.sampleList[idx].inspect.id;
    },
    // 下拉刷新
    onRefresh() {
      // this.sampleList = [];
      // this.params.page = 1;
      // this.onLoad();

      this.finished = false;
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.onLoad();
    },

    // 复检审核
    recheckBtn(item) {
      Dialog.alert({
        title: "复检确认",
        message: "您确定要提交复检请求吗？",
        theme: "round-button",
        showCancelButton: true,
      })
        .then(() => {
          this.$post("check/reset-and-get-reset-info", "api", {
            id: item.id,
          }).then((res) => {
            this.$notify({ type: "success", message: res.message });
            this.onLoad();
          });
        })
        .catch(() => {
          // on cancel
          Dialog.close;
        });
    },
  },

  mounted() {
    // this.$store.dispatch('restoreScrollPosition')
    // 在组件重新加载时恢复滚动位置
    // if (this.savedScrollPosition) {
    //   console.log(this.savedScrollPosition)
    //   window.scrollTo(this.savedScrollPosition.x, this.savedScrollPosition.y)
    //   this.savedScrollPosition = null // 重置 savedScrollPosition
    // }
  },
};
</script>

<style scoped>
.status {
  position: absolute;
  top: 10px;
  left: 120px;
  width: 80px;
  height: 80px;
  /* opacity: 40%; */
  /* transform:rotate(-45deg);
  -webkit-transform:rotate(-45deg);
  -moz-transform:rotate(-45deg); */
}
.border-tb {
  border-top: dashed 1px #f5f5f5;
  border-bottom: dashed 1px #f5f5f5;
  padding: 8px 0;
  margin: 8px 0;
}
.elasticlayer {
  height: 60%;
}
.tac {
  text-align: center;
}
.add-one {
  width: 50px;
  height: 50px;
  background: wheat;
  position: fixed;
  bottom: 100px;
  z-index: 99999;
  border-radius: 50%;
  text-align: center;
  opacity: 0.8;
  right: 0;
  cursor: pointer;
}
.red {
  color: red;
}
.van-cell-group--inset {
  height: 300px;
  overflow: auto;
}
.icon-del {
  position: absolute;
  right: 20px;
  z-index: 99;
  top: 10px;
}
.icon-add {
  margin-left: 20px;
}
.searchInp {
  position: fixed;
  top: 0;
  width: -webkit-fill-available;
  z-index: 9999;
}
.import-excel {
  width: 50px;
  height: 50px;
  background: wheat;
  position: fixed;
  bottom: 150px;
  z-index: 999;
  border-radius: 50%;
  text-align: center;
  opacity: 0.8;
  right: 0;
  cursor: pointer;
}
.van-tag {
  border-radius: 16px;
}
.label {
  max-width: 480px;
  width: 90%;
  margin: 10px auto;
  border-radius: 6px;
  overflow: hidden;
}
.label h3 {
  text-align: center;
  font-size: 12px;
  line-height: 1.268em;
  margin: 0 auto;
  color: #000000;
}
.label .qrlist {
  margin: 0;
  padding: 6px 0;
  border-top: solid 2px #999;
  border-bottom: solid 2px #999;
}
.label .qrlist p {
  letter-spacing: -1px;
  line-height: 1.2em;
  font-size: 12px;
  color: #000000;
  margin: 0;
  padding: 0;
  white-space: nowrap;
}
.qrlist .qr-bt {
  transform: scale(0.8, 0.8);
}
.qrlist .qr-bt {
  margin-top: -36px;
  margin-left: -20px;
  transform: scale(0.7, 0.7);
}
.qrlist .qr-lt {
  transform: scale(0.6, 0.6);
  margin-top: -20px;
  margin-left: -16px;
  position: relative;
  z-index: 1;
}
.qrlist .qr-rt {
  transform: scale(0.6, 0.6);
  margin-top: -14px;
  position: relative;
  z-index: 10;
}
.qrlist .qr-lt p.fs16 {
  font-size: 16px;
}
.btgroup {
  padding: 10px 10px 20px;
}
.printBtn {
  width: 60%;
  margin: 5px 20% 15px;
}
.qrlist .qr-lt > p.fs16 {
  font-size: 16px;
}
.btn-grp {
  width: 100%;
  padding: 8px 15px 5px;
  margin: 0 auto;
  text-align: center;
  display: flex;
  justify-content: space-around;
}
</style>